import {
	Box,
	Flex,
	Text,
	IconButton,
	Stack,
	Collapse,
	Link,
	useDisclosure,
} from '@chakra-ui/react';
import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons';
import { Link as RouteLink } from 'react-router-dom';

export default function NavBar() {
	const { isOpen, onToggle } = useDisclosure();

	return (
		<Box>
			<Flex
				bg={'white'}
				color={'gray.600'}
				minH={'60px'}
				py={{ base: 2 }}
				px={{ base: 4 }}
				borderBottom={1}
				borderStyle={'solid'}
				borderColor={'gray.200'}
				align={'center'}
			>
				<Flex
					flex={{ base: 1, md: 'auto' }}
					ml={{ base: -2 }}
					display={{ base: 'flex', md: 'none' }}
				>
					<IconButton
						onClick={onToggle}
						icon={
							isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />
						}
						variant={'ghost'}
						aria-label={'Toggle Navigation'}
					/>
				</Flex>
				<Flex flex={{ base: 1 }} justify={{ base: 'end', md: 'center' }}>
					<Flex display={{ base: 'none', md: 'flex' }} ml={10}>
						<DesktopNav />
					</Flex>
				</Flex>
				{/* <Flex display={{ base: 'none', md: 'flex' }} justify={'end'}>
					<Button onClick={toggleColorMode} size="sm">
						{colorMode === 'light' ? <MoonIcon /> : <SunIcon />}
					</Button>
				</Flex> */}
			</Flex>

			<Collapse in={isOpen} animateOpacity>
				<MobileNav />
			</Collapse>
		</Box>
	);
}

const DesktopNav = () => {
	const linkColor = 'gray.600';
	const linkHoverColor = 'gray.800';

	return (
		<Stack direction={'row'} spacing={4}>
			{NAV_ITEMS.map((navItem) => (
				<Box key={navItem.label}>
					<Link
						key={navItem.label}
						href={navItem.href}
						isExternal={navItem.href.endsWith('.pdf')}
						target={navItem.href.endsWith('.pdf') ? '_blank' : undefined}
						as={RouteLink}
						to={navItem.href}
						p={2}
						fontSize={'md'}
						fontWeight={500}
						color={linkColor}
						_hover={{
							color: { linkHoverColor },
						}}
					>
						{navItem.label}
					</Link>
				</Box>
			))}
		</Stack>
	);
};

const MobileNav = () => {
	return (
		<Stack bg={'white'} p={4} display={{ md: 'none' }}>
			{NAV_ITEMS.map((navItem) => (
				<MobileNavItem key={navItem.label} {...navItem} />
			))}
		</Stack>
	);
};

const MobileNavItem = ({ label, children, href }: NavItem) => {
	return (
		<Stack spacing={4}>
			<Flex
				py={2}
				px={1}
				as={Link}
				href={href ?? '#'}
				justify={'space-between'}
				align={'center'}
				_hover={{
					textDecoration: 'none',
				}}
			>
				<Text fontWeight={600} color={'gray.600'}>
					{label}
				</Text>
			</Flex>
		</Stack>
	);
};

interface NavItem {
	label: string;
	subLabel?: string;
	children?: Array<NavItem>;
	href?: string;
}

const NAV_ITEMS: Array<NavItem> = [
	{
		label: 'Home',
		href: '/home',
	},
	{
		label: 'Projects',
		href: '/projects',
	},
	{
		label: 'Resume',
		href: '/pdfs/resume.pdf',
	},
	// {
	// 	label: 'Blog',
	// 	href: '/blog',
	// },
];
