import { Flex, Image, Heading, Text, Fade } from "@chakra-ui/react";
import underConstruction from "../static/under-construction.svg";

export default function UnderConstructionComponent() {
	return (
		<Fade in={true}>
			<Flex
				direction="column"
				justify="start"
				align="center"
				minH={{ base: "300px", md: "600px" }}
				px={10}
			>
				<Flex
					minW="100%"
					direction="column"
					justify="start"
					align="center"
					py={10}
					minH={{ base: "300px", md: "400px" }}
				>
					<Heading
						fontSize={{ base: "3xl", md: "4xl" }}
						mb={6}
						width="100%"
						textAlign="center"
					>
						Blog
					</Heading>
					<Image
						src={underConstruction}
						alt="Work In Progress"
						width={{ sm: "60%", md: "40%" }}
					/>
					<Text fontSize="xl" textAlign="center" color="grey">
						This page is still a work in progress. Sit tight!
					</Text>
				</Flex>
			</Flex>
		</Fade>
	);
}
